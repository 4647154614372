import React, { useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { saasTheme } from 'common/src/theme/saas';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../containers/Saas/Navbar';
import BannerSection from '../containers/Saas/BannerSection';
import FeatureSection from '../containers/Saas/FeatureSection';
import VisitorSection from '../containers/Saas/VisitorSection';
import ServiceSection from '../containers/Saas/ServiceSection';
import FaqSection from '../containers/Saas/FaqSection';
import Footer from '../containers/Saas/Footer';
import TestimonialSection from '../containers/Saas/TestimonialSection';
import PartnerSection from '../containers/Saas/PartnerSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

function getQueryParams() {
  return new URLSearchParams(window.location.search);
}

export default () => {
  const size = process.browser && useWindowSize();
  const innerWidth = process.browser && size.innerWidth;
  const queryParams = process.browser && getQueryParams();
  const name = queryParams && queryParams.get('name', null);
  const company = queryParams && queryParams.get('company', null);
  const persona = queryParams && queryParams.get('persona', null);
  const focus = queryParams && queryParams.get('focus', null);
  const videoURL = queryParams && queryParams.get('video_url', null);
  const tagline = queryParams && queryParams.get('tagline', null);
  const brand = queryParams && queryParams.get('brand', null);
  let smbType = queryParams && queryParams.get('smb_type', 'Dealer');
  if (!smbType) {
    smbType = 'Dealer';
  }

  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title={`goBoost | Make Every ${smbType} Your Best ${smbType}`} />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection
            name={name}
            company={company}
            persona={persona}
            focus={focus}
            videoURL={videoURL}
            tagline={tagline}
            smbType={smbType}
          />
          <FeatureSection smbType={smbType} />
          <VisitorSection smbType={smbType} />
          <TestimonialSection brand={brand} smbType={smbType} />
          <ServiceSection smbType={smbType} />
          <PartnerSection brand={brand} smbType={smbType} />
          <FaqSection smbType={smbType} />
          <Footer smbType={smbType} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
